ul {
  margin: 0;
  padding: 0;
}

header {
  margin-bottom: 40px;
}

header .logo {
  display: inline-block;
  margin-top: 20px;
  max-width: 330px;
}

.logo img {
  width: 100%;
}

.marker {
  width: 25px;
  height: 25px;
  background: url("/images/icon.png") !important;
}

footer {
  clear: both;
  width: 100%;
  color: #1d1d1b;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0px;
  padding: 20px 0 10px;
}

.stores {
  clear: both;
  margin-top: 20px;
}

.store-list h2 {
  display: inline-block;
  margin: 0;
}

@media (max-width: 767px) {
  .store-list h2 {
    margin-bottom: 10px;
  }
}

.store {
  overflow: hidden;
  background: #eee;
  padding: 0;
  margin-right: 1.25%;
  width: 32%;
  min-height: 235px;
  margin-bottom: 15px;
  padding: 15px;
}

.store--featured {
  width: 100%;
  padding: 25px 50px 0;
  margin-bottom: 50px;
  border-radius: 20px;
}
.carousel .slide {
  text-align: left;
}

.carousel .control-dots .dot {
 background: #337ab7;
}

.store img {
  width: 150px;
  height: 150px;
  float: left;
  margin: 0;
}

.store--featured img.image--featured {
  width: 33%;
  height: auto;
  padding: 0 25px 25px 0;
}

.store--featured .store-details a {
  white-space: normal;
}

.store--featured .store-details a h2 {
  line-height: 1.2;
}

/* featured store socials */
.socials {
  list-style: none;
  margin: 20px 0;
}

.socials li img {
  width: 24px !important;
  height: auto;
  margin-bottom: 20px;
}

.socials li a:hover {
  opacity: 0.6;
}

@media (max-width: 767px) {
  .store-text {
    float: left;
  }
  .store--featured {
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  .store--featured h3 {
    margin-bottom: 20px !important;
  }

  .store--featured img.image--featured {
    width: 100%;
  }

  .carousel.carousel-slider {
    overflow: visible;
  }

  .control-dots {
    margin-bottom: -15px !important;
  }
}

.store h3 {
  padding: 0;
  margin: 0 0 9px;
}

.store address {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .store {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

nav {
  float: right;
  margin-top: 20px;
}

@media (max-width: 767px) {
  nav {
    float: none;
  }
}

nav a {
  margin-right: 15px;
  font-weight: 600;
  text-decoration: underline;
}

nav a.active {
  text-decoration: none;
  color: #23527c;
}

.btn-fav {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border: none;
}

@media (max-width: 767px) {
  .btn-fav {
    float: right;
    margin-right: 15px;
  }
}

.btn-fav.addfav {
  background: url("/images/addfav.png") no-repeat center;
  background-size: cover;
}

.btn-fav.addfav:hover {
  background: url("/images/addfav--hover.png") no-repeat center;
  background-size: cover;
}

.btn-fav.isfav {
  background: url("/images/isfav.png") no-repeat center;
  background-size: cover;
  cursor: default !important;
}

.btn-fav.removefav {
  background: url("/images/removefav.png") no-repeat center;
  background-size: cover;
}

.btn-fav.removefav:hover {
  background: url("/images/removefav--hover.png") no-repeat center;
  background-size: cover;
}

.btn-fav:hover {
  cursor: pointer;
}

.info-window h2 {
  margin: 0 0 10px !important;
}

.info-window address {
  margin: 0 0 10px !important;
}

.info-window .phone-link {
  margin: 0 0 5px !important;
  display: inline-block;
}

.content {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .content {
    margin-bottom: 30px;
  }
}

.store-map {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .store-map {
    margin-bottom: 0;
  }
}

.region-selector {
  font-size: 1.25em;
}

.store-map .region-selector {
  font-size: 1.5em;
}

.advert {
  clear: both;
}

.advert img {
  width: 100%;
}

.store-edit {
  margin-bottom: 10px;
}

.store-edit input,
.store-edit button {
  width: 8%;
  margin-right: 1%;
}
.btn-logout {
  margin-top: -15px;
  margin-bottom: 40px;
}

label {
  font-size: 1.25em;
}

.filter-container {
  float: right;
  display: inline;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .filter-container {
    display: block;
    clear: both;
    float: none;
    margin-right: 0;
  }

  .store-details a {
    white-space: nowrap;
  }
}

@media (max-width: 320px) {
  .store-details {
    margin-left: -15px;
  }

  .btn-fav {
    margin-right: -5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .store {
    position: relative;
    min-height: 378px;
  }
  .store .col-sm-1 {
    position: absolute;
    top: 45px;
    right: 15px;
  }
  .store-details {
    white-space: nowrap;
    clear: both;
  }
  .store img {
    width: 175px;
    height: 175px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .store {
    min-height: 282px;
  }
  .store-details {
    margin-left: -15px;
  }
}

